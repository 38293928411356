import React, { useEffect, useState } from "react";

export const onPrevPage = (currentPage, pageChangeHandler, filterStatus) => {
  if (currentPage > 1) {
    pageChangeHandler(currentPage - 1, filterStatus);
  } else {
    return null;
  } 
};
export const onNextPage = (
  currentPage,
  pageChangeHandler,
  totalNoOfPages,
  filterStatus
) => {
  if (currentPage < totalNoOfPages) {
    pageChangeHandler(currentPage + 1, filterStatus);
  } else {
    return null
  }
};

function Pagination({
  pageChangeHandler,
  totalNoOfPages,
  currentPage,
  filterStatus,
}) {
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);

  
  useEffect(() => {
    setCanGoNext(currentPage < totalNoOfPages);
    setCanGoBack(currentPage > 1);
  }, [currentPage, totalNoOfPages]);

  return (
    <div className="flex  flex-row justify-center">
      <div>
        <button
          data-testid="Previous Page"
          onClick={() =>
            onPrevPage(currentPage, pageChangeHandler, filterStatus)
          }
          disabled={!canGoBack}
          className="p-3  bg-[#F2A900] disabled:opacity-25 rounded-xl m-4 w-40 text-white"
        >
          Previous Page
        </button>
      </div>
      <div>
        <button
          data-testid="ch"
          onClick={()=>onNextPage(
            currentPage,
            pageChangeHandler,
            totalNoOfPages,
            filterStatus
          )}
          disabled={!canGoNext}
          className="p-3  bg-[#F2A900] disabled:opacity-25 rounded-xl m-4 w-40 text-white"
        >
          Next Page
        </button>
      </div>
    </div>
  );
}

export default Pagination;
