import React from "react";
import { Link } from "react-router-dom";

function NavBar() {
  return (
    <>
      <div
        className="w-80 h-auto flex  bg-[#F2A900] text-white m-4 p-2 rounded-2xl sticky overflow-scroll scrollbar-hide flex-col"
        data-testid="nav-bar"
      >
        <div className="header felx border-b-1 divide-red-900">
          <div className="flex text-2xl bg-white rounded-xl p-2">
            <img
              className="rounded"
              width="70%"
              src="https://assets.talview.com/core/656/other/167646554669310.jpg"
              alt="branding_logo"
            />
          </div>
        </div>
        <div className="flex justify-center my-5 relative">
          <ul className="min-w-full">
            <Link to="/Dashboard">
            <li className="my-5 hover:bg-[#4B515B] p-4 rounded-md">
              Dashboard
              </li>
            </Link>
            <Link to="/PurchaseCredits">
            <li className="my-5 hover:bg-[#4B515B] p-4 rounded-md">
              Purchase Credits
              </li>
            </Link>
            <Link to="/CandidateDetails">
            <li className="my-5 hover:bg-[#4B515B] p-4 rounded-md">
              Candidate Details
              </li>
              </Link>
          </ul>
        </div>
      </div>
    </>
  );
}

export default NavBar;
