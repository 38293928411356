import React from "react";

function NoPageFound() {
  return (
    <div className="flex items-center justify-center  w-full h-full border">
      <div className="flex  border bg-[#060b26] bg-opacity-70  text-white p-4 m-2 rounded-2xl">
        <p>no page found</p>
      </div>
    </div>
  );
}

export default NoPageFound;
