import React, { useState, useContext, useEffect } from "react";
import BaseLayout from "../BaseLayout/Index";
import TableHead from "./TableHead";
import API from "../../Components/utiles";

export let leftHandler = function (setFirstDiv) {
    setFirstDiv(false);
  };
 export let rightHandler = function (setFirstDiv) {
    setFirstDiv(true);
 };
  export async function DashboardFunction(dashboardApi,setSingleData,setBundleData,setLoading,seterrorMsg) {
      try {
        const dashboardapi = await dashboardApi();
        setSingleData(dashboardapi);
        setBundleData(dashboardapi);
        setLoading(false);
      } catch (err) {
        seterrorMsg(err.message);
        setLoading(false);
        
      }
    }

const Dashboard = () => {
  const { dashboardApi } = API();
  const [firstdiv, setFirstDiv] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
 


  const [singleData, setSingleData] = useState({});
  const [bundleData, setBundleData] = useState({});


  useEffect(() => {
    setLoading(true);
    DashboardFunction(dashboardApi,setSingleData,setBundleData,setLoading,seterrorMsg);
  }, []);

  return (
    <>

      <BaseLayout page="Dashboard">
          {errorMsg && (
            <div className="mx-20 bg-gray-700 rounded-xl text-white p-2 m-2"data-testid="error-msg">
              {errorMsg}
            </div>
          )}
          {loading ? (
            <div className="flex #FFFFFF align-middle justify-center rounded-md p-2" data-testid="loading">
              Fetching The Data
            </div>
          ) : (
            <>
              <div className="flex  items-center justify-center  px-60 my-10">
                <div className=" flex flex-col gap-10 h-auto items-center justify-center">
                  <div className="flex justify-center ">
                    <div
                      className={`step-item hover:cursor-pointer  ${
                        !firstdiv && "active"
                      }`}
                      onClick={()=>leftHandler(setFirstDiv)}
                    >
                      <div className="step "> </div>
                      <p className="">
                        <b data-testid="Bundles">Bundles</b>
                      </p>
                    </div>
                    <div
                      className={`step-item hover:cursor-pointer  ${
                        firstdiv && "active"
                      }`}
                      onClick={()=>rightHandler(setFirstDiv)}
                    >
                      <div className="step"> </div>
                      <p className="">
                        <b>Single</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              {!firstdiv && (
                <div className="transition h-auto delay-900 duration-300 ease-in-out flex flex-col  p-5 rounded-xl" data-testid="first-div">
                  {!firstdiv && <p className="text-3xl m-2">Bundle</p>}
                  <table className="w-full">
                    <TableHead />
                    <tbody className=" text-center">
                      {bundleData.bundle &&
                        Object.keys(bundleData.bundle).map((feature, index) => (
                          <tr
                            key={index}
                            className="  border-gray-600 divide-x-0 transition duration-300 ease-in-out  "
                          >
                            <td className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                              {feature === "record_and_review"
                                ? "Record and Review"
                                : "" || feature === "live_proctor"
                                ? "Live Proctor"
                                : "" || feature === "ai_proctor"
                                ? "AI Proctor"
                                : ""}
                            </td>
                            <td className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                              {bundleData.bundle[feature].all.used}
                            </td>
                            <td className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                              {bundleData.bundle[feature].all.unassigned}
                            </td>
                            <td className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                              {bundleData.bundle[feature].all.assigned}
                            </td>
                            <td className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                              {bundleData.bundle[feature].all.total}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {/*single  */}
              <div
                className={
                  firstdiv
                    ? "transition h-auto delay-900 duration-300 ease-in-out flex flex-col  p-5 rounded-xl"
                    : ""
                }
              >
                {firstdiv && <p className="text-3xl">Single</p>}

                {singleData.single &&
                  Object.keys(singleData.single).map((proctorType, index) => (
                    <div key={index}>
                      {firstdiv && (
                        <div className=" flex flex-row  justify-between   rounded-xl p-6">
                          <div className="flex flex-col">
                            <p className="text-xl ">
                              {proctorType === "live_proctor"
                                ? "Live Proctor"
                                : ""}
                              {proctorType === "ai_proctor" ? "AI Proctor" : ""}
                              {proctorType === "record_and_review"
                                ? "Record and Review"
                                : ""}
                            </p>
                          </div>
                        </div>
                      )}
                      {firstdiv && (
                        <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                              {firstdiv && (
                                <table className="w-full">
                                  <TableHead />
                                  <tbody className=" text-center border">
                                    {singleData.single &&
                                      Object.keys(
                                        singleData.single[proctorType]
                                      ).map((examType, index) => (
                                        <tr
                                          key={index}
                                          className="  border-gray-600 divide-x-0 transition duration-300 ease-in-out "
                                        >
                                          <td className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                                            {examType ===
                                            "listening_and_reading"
                                              ? "Listening and Reading"
                                              : "" || examType === "writing"
                                              ? "Writing"
                                              : "" || examType === "speaking"
                                              ? "Speaking"
                                              : "" || examType}
                                          </td>
                                          <td className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                                            {
                                              singleData.single[proctorType][
                                                examType
                                              ].used
                                            }
                                          </td>
                                          <td className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                                            {
                                              singleData.single[proctorType][
                                                examType
                                              ].unassigned
                                            }
                                          </td>
                                          <td className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                                            {
                                              singleData.single[proctorType][
                                                examType
                                              ].assigned
                                            }
                                          </td>
                                          <td className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                                            {
                                              singleData.single[proctorType][
                                                examType
                                              ].total
                                            }
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </>
          )}
        </BaseLayout>
    </>
  );
};
export default Dashboard;
