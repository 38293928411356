import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./container/Auth/LoginPage";
import Dashboard from "./container/Dashboard";
import PurchaseCredits from "./container/PurchaseCredits";
import AuthContext from "./Components/store/auth-context";
import NoPageFound from "./container/NoMatch/NoPageFound";
import PaymentSuccess from "./container/PurchaseCredits/PaymentSuccess/Index";
import PaymentFailed from "./container/PurchaseCredits/PaymentFailed/Index";
import CandidateDetails from "./container/CandidateDetails"

//testing remove later

function App() {
  const contextloginstatus = useContext(AuthContext);
  const isLoggedIn = contextloginstatus.isLoggedIn;

  return (
    <div className="w-screen h-screen flex flex-ROW bg-[#ECF1F5]">
      {/* {isLoggedIn && <SsTable />} */}
      <Routes>
        {!isLoggedIn && <Route path="/" element={<Login />}></Route>}
        {isLoggedIn && (
          <Route path="/Dashboard" element={<Dashboard />}></Route>
        )}
        {isLoggedIn && <Route path="/" index element={<Dashboard />}></Route>}
        {isLoggedIn && (
          <Route path="/PurchaseCredits" element={<PurchaseCredits />}></Route>
        )}
        {isLoggedIn && (
          <Route
            path="/CandidateDetails"
            element={<CandidateDetails />}
          ></Route>
        )}
        {isLoggedIn && <Route path="*" element={<NoPageFound />}></Route>}
        {isLoggedIn && (
          <Route path="success" element={<PaymentSuccess />}></Route>
        )}
        {isLoggedIn && <Route path="fail" element={<PaymentFailed />}></Route>}
      </Routes>
    </div>
  );
}

export default App;
