import React from "react";
import { Link } from "react-router-dom";

function PaymentSuccess() {
  return (
    <div className="flex flex-col items-center justify-center  w-full h-full border">
      <div className="flex  border bg-[#060b26] bg-opacity-70  text-white p-4 m-2 rounded-2xl">
        Payment Success
      </div>
      <div className="m-2">
        <Link to="/Dashboard">
          <span
            style={{ cursor: "pointer" }}
            rel="noopener noreferrer"
            className="px-8 py-3 font-semibold rounded bg-[#F2A900] text-gray-900"
          >
            Dashboard
          </span>
        </Link>
      </div>
    </div>
  );
}

export default PaymentSuccess;
