import React,{useState} from 'react'

function DropDown({options, defaultValue, onChange,...props}) {
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const handleChange = (e) => {
        const value = e.target.value;
        setSelectedValue(value)
        onChange(value);  
    }
    
  return (
    <>
      <select value={selectedValue} onChange={handleChange} {...props}>
        
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
}
export default DropDown

