import React from "react";

function AssessmentData(props) {
  return (
    <>
      <tr className=" border-b border-gray-600 divide-x-0 transition duration-300 ease-in-out  ">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
          {props.name}
        </td>
        <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
          $ {props.price}
        </td>
        <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
          {props.inputdata}
        </td>
      </tr>
    </>
  );
}

export default AssessmentData;
