import React, { useEffect, useMemo, useState } from "react";
import { useTable, useFilters, usePagination, useRowSelect } from "react-table";
import Loader from "./Loader";
import { Checkbox } from "../Checkbox/Checkbox";

const Table = ({
  columns,
  data,
  isLoading,
  manualPagination,
  selectedData,
}) => {
  const columnData = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => data, [data]);
  const [selectedRows, setSelectedRows] = useState([]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns: columnData,
      data: rowData,
      manualPagination,
    },
    useFilters,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <Checkbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    const newSelectedRows = selectedFlatRows.map((row) => row.original);

    if (!arraysEqual(selectedRows, newSelectedRows)) {
      setSelectedRows(newSelectedRows);
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    selectedData(selectedRows);
  }, [selectedData, selectedRows]);

  function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }

    return true;
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <table {...getTableProps()} className="min-w-full">
            <thead className=" border-b  border-gray-600">
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="text-gray-400"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="text-sm px-3 py-3  font-medium  text-left"
                    >
                      {column.render("Header")}

                      {column.canFilter && <div>{column.render("Filter")}</div>}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className=" border-b  border-gray-600 divide-x-0 transition duration-300 ease-in-out  "
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="px-3 py-3 whitespace-nowrap text-sm font-medium"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default Table;
