import React, { useContext } from "react";
import NavBar from "../../Components/NavBar";
import { AiOutlineUserDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import AuthContext from "../../Components/store/auth-context";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
export  const logoutHandler = (AUTHCtx) => {
  AUTHCtx.logout();
};
function BaseLayout(props) {
  const AUTHCtx = useContext(AuthContext);


  return (
    <>
      <NavBar />
      <ToastContainer position="top-right" theme="colored" autoClose={3000} />
      <div
        className=" relative w-full  h-6/6 m-2   overflow-scroll scrollbar-hide  rounded-2xl "
        data-testid="base-layout"
      >
        <div className="h-auto   rounded-xl  items-center bg-[#75787B]">
          <div className=" flex flex-row  justify-between   rounded-xl p-4">
            <div className="flex flex-col  p-2 rounded-md text-white">
              <div>
                <b>{props.page}</b>
              </div>
            </div>
            <div className="flex flex-row w-auto justify-between items-center mx-12">
                <Link to="/">
                  <div className="flex flex-row w-20 justify-between text-md hover:bg-yellow-500 rounded-md">
                    <button
                      data-testid="button"
                      onClick={() => logoutHandler(AUTHCtx)}
                      className=" p-2 rounded-md text-white"
                    >
                      <b>
                        <AiOutlineUserDelete />
                        Logout
                      </b>
                    </button>
                  </div>
                </Link>
            </div>
          </div>
        </div>
        <div className="h-full">{props.children}</div>
      </div>
    </>
  );
}

export default BaseLayout;
