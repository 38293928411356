import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../../Components/Input";
import Button from "../../../Components/Button";
import AuthContext from "../../../Components/store/auth-context";

import API from "../../../Components/utiles/index";

const initialValues = {
  Email: "",
  Password: "",
};
export async function loginHandler(e,setIsLoading,loginApi,values,authCtxStatus,navigate,seterrorMsg) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const apiResponse = await loginApi(values.Email, values.Password);
      const responceData = apiResponse.data;
      authCtxStatus.login(responceData.access_token, responceData.expires_in);
      navigate("/Dashboard");
    } catch (err) {
      setIsLoading(false);
      seterrorMsg(err.message);
    } finally {
      setIsLoading(false);
    }
  }

function Login() {
  const { loginApi } = API();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [errorMsg, seterrorMsg] = useState("");
  const authCtxStatus = useContext(AuthContext);

  const handleInputChange = (e) => {
    seterrorMsg("");
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  

  return (
    <div
      className="w-full h-full  shadow-4xl opacity-80 flex justify-center items-center backdrop-filter backdrop-filter-3xl rounded-xl"
      data-testid="login-component"
    >
      <div className="bg-white justify-center items-center w-auto shadow-2xl flex flex-col md:flex-row mx-5 sm:m-0 rounded-2xl">
        <div className="border-l bg-[#F2A900] m-w-full md:w-1/2 flex flex-col items-center py-40 rounded-3xl">
          <div className="text-2xl italic text-white">
            <b>Sign In</b>
          </div>

          <form
            action="#"
            className="w-full flex flex-col justify-center rounded-2xl  p-4"
          >
            <div className="mb-4">
              <Input
                type="email"
                placeholder="Email"
                name="Email"
                value={values.Email}
                onChange={handleInputChange}
                required="required"
                className="p-2"
                data-testid="Email"
              />
            </div>
            <div className="mb-4">
              <Input
                type="password"
                placeholder="Password"
                value={values.Password}
                name="Password"
                onChange={handleInputChange}
                required="required"
                data-testid="Password"
              />
            </div>
            <div className="mb-2 text-red-900 ">{errorMsg}</div>

            <Button
              type="submit"
              buttonText="Login"
              isLoading={isLoading}
              onclick={(e)=>loginHandler(e,setIsLoading,loginApi,values,authCtxStatus,navigate,seterrorMsg)}
              data-testid="login-button"
            />
          </form>
        </div>
        <div className="flex justify-center items-center">
          <img
            className="rounded-xl"
            width="70%"
            src="https://assets.talview.com/core/656/other/167646554669310.jpg"
            alt="Logo"
            data-testid="logo-image"
          />
        </div>
      </div>
    </div>
  );
}

export default Login;



