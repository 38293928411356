import React, { useState, useEffect } from "react";

import Pagination from "../../Components/Table/Pagination";
import ReactTable from "../../Components/Table/Index";
import BaseLayout from "../BaseLayout/Index"
import DropDown from "../../Components/Dropdown";
import Input from "../../Components/Input";
//icons
import { AiFillDelete } from "react-icons/ai";
import { IoCloudUploadSharp, IoCloudDownload } from "react-icons/io5";
//apis
import API from "../../Components/utiles";
//toast
import { toast } from "react-toastify";
export const deleteHandler = async (selectedRows,deleteCandidate,handlePageChange,pageData) => {
    const filteredRows = selectedRows.filter((item) => item.status !== "Used");
    const result = filteredRows.map((item) => ({
      external_invitation_id: item.external_invitation_id,
    }));

    if (selectedRows.length === 0) {
      toast.error("Select a candidate to delete", {
        position: "bottom-right",
        theme: "dark",
      });
    } else if (filteredRows.length !== selectedRows.length) {
      toast.error("Candidates with 'used' status cannot be deleted", {
        position: "bottom-right",
        theme: "dark",
      });
    } else {
      try {
        const deleteCandidateApi = await deleteCandidate(result)
        if (deleteCandidateApi.status === 200) {

          toast.success("Candidate deleted successfully!", {
            position: "bottom-right",
            theme: "dark",
          });
handlePageChange(pageData.currentPage);
        } else {
          toast.error("Failed to fetch the delete API", {
            position: "bottom-right",
            theme: "dark",
          });
        }
      } catch (error) {
        toast.error(error.message, {
          position: "bottom-right",
          theme: "dark",
        });
      }
    }
};
export const downloadTemplates = async (downloadTemplate,sethreff) => {
    try {
      const responce = await downloadTemplate();
      sethreff("data:text/csv;charset=utf-8," + responce.data);
    } catch (error) {

    }
  };
export const handleAssessmentChange = (value,setAssessmentID,setPageData) => {
    setAssessmentID(value);
    setPageData((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
};
  
export const handleStatuschange = (value,setStatusValues,setPageData) => {
    setStatusValues(value);
    setPageData((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
};
  
export const handleFileUpload = async (e, fileUpload) => {
    try {
      const formData = new FormData();
      formData.append("bulk-upload", e.target.files[0]);
      const response = await fileUpload(formData);
      if (response.status === 200) {
        toast.success("File upload sucessfull!", {
          position: "bottom-right",
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("File upload Failed", {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };
function CandidateDetails() {
  const {
    downloadTemplate,
    fileUpload,
    candidateDetailsPagination,
    deleteCandidate,
  } = API();

  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    currentPage: 1,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [statusValues, setStatusValues] = useState("");
  const [AssessmentID, setAssessmentID] = useState("");

  useEffect(() => {
    handlePageChange(pageData.currentPage);
  }, [statusValues, AssessmentID]);

  //
  const AssessmentIdoptions = [
    { value: "", label: "Select The value" },
    // { value: "SLPSP", label: "SLPSP" },
    // { value: "SLPLSRE", label: "SLPLSRE" },
    // { value: "SLPWR", label: "SLPWR" },
    { value: "SRRSP", label: "SRRSP" },
    { value: "SRRLSRE", label: "SRRLSRE" },
    { value: "SRRWR", label: "SRRWR" },
    { value: "SAISP", label: "SAISP" },
    { value: "SAILSRE", label: "SAILSRE" },
    { value: "SAIWR", label: "SAIWR" },
    // { value: "BLPALL", label: "BLPALL" },
    { value: "BRRALL", label: "BRRALL" },
    { value: "BAIALL", label: "BAIALL" },
  ];
  const statusOptions = [
    { value: "", label: "Select Your value" },
    { value: "Assigned", label: "Assigned" },
    { value: "Used", label: "Used" },
  ];

  
  
  const columns = [
    {
      Header: "External Invitation ID",
      accessor: "external_invitation_id",
      disableFilters: true,
    },
    {
      Header: "Assessment Type",
      accessor: "assessment_type",
      Filter: ({ column }) => (
        <DropDown
          data-testid="assessment-dropdown"
          options={AssessmentIdoptions}
          defaultValue={AssessmentID}
          onChange={(value)=>handleAssessmentChange(value,setAssessmentID,setPageData)}
        />
      ),
    },
    {
      Header: "Username",
      accessor: "username",
      // Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Password",
      accessor: "password",
      disableFilters: true,
    },
    {
      Header: "Institution ID",
      accessor: "institution_id",
      disableFilters: true,
    },

    {
      Header: "Candidate Email",
      accessor: "candidate_email",
      disableFilters: true,
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: ({ column }) => (
        <DropDown
          data-testid="status-dropdown"
          options={statusOptions}
          defaultValue={statusValues}
          onChange={(value)=>handleStatuschange(value,setStatusValues,setPageData)}
        />
      ),
    },
  ];

  async function handlePageChange(pageNumber) {
    setPageData((prevState) => ({
      ...prevState,
      isLoading: true,
      currentPage: pageNumber,
    }));

    try {
      const response = await candidateDetailsPagination(pageNumber,statusValues,AssessmentID);
      const totalPageCount = response.headers["x-pagination-page-count"];

      setPageData((prevState) => ({
        ...prevState,
        isLoading: false,
        rowData: response.data,
        totalPages: totalPageCount,
      }));
    } catch (err) {
      setPageData((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setErrorMsg(err.message);
    }
  }

  //download template
  const [hreff, sethreff] = useState();
  //file upload
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
  };
  return (
    <>
      <BaseLayout page="Candidate Details">
        <div className="w-full h-full m-2     rounded-2xl ">
          <div className="flex flex-col   p-5 rounded-xl ">
            <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
              <div className="py-2 inline-block min-w-full ">
                {errorMsg && (
                  <div className="mx-20 bg-gray-700 rounded-xl text-white p-2">
                    {errorMsg}
                  </div>
                )}
                <div className="flex justify-between items-center my-2">
                  <div className="mx-20 "></div>
                  <div className="m-2 flex ">
                    <div></div>
                    <div className="text-4xl hover:text-5xl  justify-end mx-5  rounded-full">
                      <Input
                        data-testid="fileupload"
                        htmlFor="fileUpload"
                        labelInnerHtml={<IoCloudUploadSharp />}
                        id="fileUpload"
                        type="file"
                        name="file upload"
                        styles="hidden"
                        onChange={(e)=>handleFileUpload(e, fileUpload)}
                      />
                    </div>
                    <div className="text-4xl hover:text-5xl  justify-end mx-5  ">
                      <button onClick={()=>downloadTemplates(downloadTemplate,sethreff)} data-testid="downloadTemplates-button">
                        <a href={hreff} download="file.csv">
                          <IoCloudDownload />
                        </a>
                      </button>
                    </div>

                    <div className="text-4xl hover:text-5xl  justify-end mx-5">
                      <button onClick={(e)=>deleteHandler(selectedRows,deleteCandidate,handlePageChange,pageData)} data-testid="delete-button">
                        <AiFillDelete />
                      </button>
                    </div>
                  </div>
                </div>

                <ReactTable
                  columns={columns}
                  data={pageData.rowData}
                  isLoading={pageData.isLoading}
                  selectedData={handleSelectedRows}
                />

                <Pagination
                  totalNoOfPages={pageData.totalPages}
                  pageChangeHandler={handlePageChange}
                  currentPage={pageData.currentPage}
                  filterStatus={statusValues}
                />
              </div>
            </div>
          </div>
        </div>
      </BaseLayout>
    </>
  );
}

export default CandidateDetails;
