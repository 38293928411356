import React from "react";

function TableHead() {
  return (
    <>
      <thead className=" border-b  text-center">
        <tr className=" text-center   ">
          <th className="text-sm  font-medium px-6 py-4 ">Assessment Type</th>
          <th className="text-sm  font-medium px-6 py-4 ">Used</th>
          <th className="text-sm  font-medium px-6 py-4 ">Unassigned</th>
          <th className="text-sm  font-medium px-6 py-4 ">Assigned</th>
          <th className="text-sm  font-medium px-6 py-4 ">Total</th>
        </tr>
      </thead>
    </>
  );
}

export default TableHead;
