import React, { useState, useContext, useEffect } from "react";
import BaseLayout from "../BaseLayout/Index";
import AssessmentData from "./AssessmentData";
import Input from "../../Components/Input";
import Button from "../../Components/Button";

import { useNavigate } from "react-router-dom";
import API from "../../Components/utiles";
export  async function paymentHandler(e,setIsLoading,paymentApi,seterrorMsg,setBDisabled,paydata) {
    e.preventDefault();
    setIsLoading(true);

    const form = e.target;
    if (form.checkValidity()) {
      try {
        const paymentapi = await paymentApi(paydata)
        if (paymentapi.status === 200) {
          window.location.replace(paymentapi.data.payment_url);
        }
      } catch (err) {
        setIsLoading(false);
        seterrorMsg(err.message);

      }
    } else {
      setBDisabled(false);

    }
}
  export function validationCheck(first, second, third, fourth, fifth, sixth) {
    return (
      first > 0 &&
      first >= 100 &&
      (second <= 0 || second >= 100) &&
      (third <= 0 || third >= 100) &&
      (fourth <= 0 || fourth >= 100) &&
      (fifth <= 0 || fifth >= 100) &&
      (sixth <= 0 || sixth >= 100)
    );
  }

function PurchaseCredits() {
  const { paymentApi } = API();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [bDisabled, setBDisabled] = useState(null);
  const priceMap = {
    sas: 1.00,
    sal: 1.00,
    saw: 1.00,
    srs: 6.00,
    srl: 6.00,
    srw: 6.00,
    sls: 12.00,
    sll: 12.00,
    slw: 12.00,
    bundleAI: 2.50,
    bundlerr: 9.00,
    bundleLsp: 18.00,

  };
  const [state, setstate] = useState({
    sas: 0,
    saw: 0,
    sal: 0,
    srl: 0,
    srs: 0,
    srw: 0,
    sls: 0,
    sll: 0,
    slw: 0,
    sasTotal: 0,
    salTotal: 0,
    sawTotal: 0,
    srsTotal: 0,
    srlTotal: 0,
    srwTotal: 0,
    slsTotal: 0,
    sllTotal: 0,
    slwTotal: 0,
    //
    bundleAI: 0,
    bundleAITotal: 0,
    bundlerr: 0,
    bundlerrTotal: 0,
    bundleLsp: 0,
    bundleLspTotal: 0,
  });

  function onChangeHandler(e) {
    const name = e.target.name;
    const value = Number(e.target.value);

    seterrorMsg("");
    setstate({
      ...state,
      [name]: Number(value),
      // ai
      //
      salTotal: name === "sal" ? priceMap[name] * value : state.salTotal,
      sawTotal: name === "saw" ? priceMap[name] * value : state.sawTotal,
      srsTotal: name === "srs" ? priceMap[name] * value : state.srsTotal,
      sasTotal: name === "sas" ? priceMap[name] * value : state.sasTotal,
      srlTotal: name === "srl" ? priceMap[name] * value : state.srlTotal,
      srwTotal: name === "srw" ? priceMap[name] * value : state.srwTotal,
      slsTotal: name === "sls" ? priceMap[name] * value : state.slsTotal,
      sllTotal: name === "sll" ? priceMap[name] * value : state.sllTotal,
      slwTotal: name === "slw" ? priceMap[name] * value : state.slwTotal,
      bundleAITotal:
        name === "bundleAI" ? priceMap[name] * value : state.bundleAITotal,
      bundlerrTotal:
        name === "bundlerr" ? priceMap[name] * value : state.bundlerrTotal,
      bundleLspTotal:
        name === "bundleLsp" ? priceMap[name] * value : state.bundleLspTotal,
    });
  }

  const singleQuantityTotal = [
    state.sasTotal,
    state.salTotal,
    state.sawTotal,
    state.srsTotal,
    state.srlTotal,
    state.srwTotal,
    state.slsTotal,
    state.sllTotal,
    state.slwTotal,
  ];

  const bundleQuantityTotal = [
    state.bundleAITotal,
    state.bundlerrTotal,
    state.bundleLspTotal,
  ];
  //

  //reducer fucntion 
  function singlevalueCalculator(array) {
    let data= array.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    })
    return data
  }
  //single AI total
  let singleAIList = [state.sal, state.sas, state.saw];
  let singleAITotal = singlevalueCalculator(singleAIList);

  //
  //rrtotal
  let singleLPList = [state.sll, state.sls, state.slw];
  let singleLPTotal = singlevalueCalculator(singleLPList);

  //rrtotal
  let singlerrList = [state.srl, state.srs, state.srw];
  let singlerrTotal = singlevalueCalculator(singlerrList);
  ////////////TOTAL FUCNTIONALITY VALIDATION

  const singleTotal = singlevalueCalculator(singleQuantityTotal);
  const bundleTotal = singlevalueCalculator(bundleQuantityTotal);

  

  useEffect(() => {
    if (
      validationCheck(
        singleAITotal,
        singleLPTotal,
        state.bundleAI,
        state.bundleLsp,
        state.bundlerr,
        singlerrTotal
      ) ||
      validationCheck(
        singleLPTotal,
        singleAITotal,
        state.bundleAI,
        state.bundleLsp,
        state.bundlerr,
        singlerrTotal
      ) ||
      validationCheck(
        singlerrTotal,
        singleLPTotal,
        singleAITotal,
        state.bundleAI,
        state.bundleLsp,
        state.bundlerr
      )
    ) {
      setBDisabled(false);
    } else if (
      validationCheck(
        state.bundleAI,
        singleAITotal,
        singleLPTotal,
        singlerrTotal,
        state.bundleLsp,
        state.bundlerr
      ) ||
      validationCheck(
        state.bundleLsp,
        singleLPTotal,
        singleAITotal,
        state.bundleAI,
        state.bundlerr,
        singlerrTotal
      ) ||
      validationCheck(
        state.bundlerr,
        singlerrTotal,
        singleLPTotal,
        singleAITotal,
        state.bundleAI,
        state.bundleLsp
      )
    ) {
      setBDisabled(false);
    } else {
      setBDisabled(true);
    }
  }, [
    singleAITotal,
    singleLPTotal,
    singlerrTotal,
    state.bundleAI,
    state.bundleLsp,
    state.bundlerr,
  ]);

  const grandtotal = [singleTotal, bundleTotal];
  const total = grandtotal.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  let paydata = {
    single: {
      live_proctor: {
        listening_and_reading: state.sllTotal,
        writing: state.slwTotal,
        speaking: state.slsTotal,
      },
      ai_proctor: {
        writing: state.sawTotal,
        listening_and_reading: state.salTotal,
        speaking: state.sasTotal,
      },
      record_and_review: {
        writing: state.srwTotal,
        listening_and_reading: state.srlTotal,
        speaking: state.srsTotal,
      },
    },
    bundle: {
      record_and_review: {
        all: state.bundlerr,
      },
      live_proctor: {
        all: state.bundleLsp,
      },
      ai_proctor: {
        all: state.bundleAI,
      },
    },
  };

 
  return (
    <>
      <BaseLayout page="Purchase Credits">
        <form onSubmit={(e)=>paymentHandler(e,setIsLoading,paymentApi,seterrorMsg,setBDisabled,paydata)} data-testid="form">
          <div className="flex justify-center align-middle items-center">
            <div className="flex max-w-full  w-full text-lg rounded-xl my-5  overflow-scroll scrollbar-hide">
              <div className="  mx-10 w-auto h-full ">
                <div className="  my-10 rounded-xl">
                  <p className="p-2 flex justify-center text-xl rounded-2xl bg-[#F2A900]">
                    Single
                  </p>
                  <table className="min-w-full overflow-scroll scrollbar-hide">
                    <thead className=" border-b border-gray-600">
                      <tr className="text-gray-400">
                        <th
                          scope="col"
                          className="text-sm  font-medium px-6 py-4 text-left"
                        >
                          Assessment Name
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium px-6 py-4 text-left"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium px-6 py-4 text-left"
                        >
                          Quantity
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* ai */}
                      <tr>
                        <td className="flex justify-center bg-[#F2A900] rounded-xl p-1">
                          <h2 className="text-white">AI Proctor</h2>
                        </td>
                      </tr>
                      <AssessmentData
                        name="Listening and Reading"
                        price={priceMap.sal}
                        inputdata={
                          <Input
                            data-testid="SAL"
                            min={0}
                            type="number"
                            name="sal"
                            price={priceMap.sal}
                            value={state.sal}
                            onChange={onChangeHandler}
                            step="1"
                          />
                        }
                      />

                      <AssessmentData
                        name="Writing"
                        price={priceMap.saw}
                        inputdata={
                          <Input
                            min={0}
                            type="number"
                            name="saw"
                            price={priceMap.saw}
                            value={state.saw}
                            onChange={onChangeHandler}
                            step="1"
                          />
                        }
                      />
                      <AssessmentData
                        name="Speaking"
                        price={priceMap.sas}
                        inputdata={
                          <Input
                            min={0}
                            type="number"
                            name="sas"
                            price={priceMap.sas}
                            value={state.sas}
                            onChange={onChangeHandler}
                            step="1"
                          />
                        }
                      />
                      {/* lp */}
                      <tr>
                        <td className="flex justify-center bg-[#F2A900] rounded-xl p-1">
                          <h2 className="text-white">Live Proctor</h2>
                        </td>
                      </tr>

                      <AssessmentData
                        name="Listening and Reading"
                        price={priceMap.sll}
                        inputdata={
                          <Input
                            data-testid="sll"
                            min={0}
                            type="number"
                            name="sll"
                            price={priceMap.sll}
                            value={state.sll}
                            onChange={onChangeHandler}
                            step="1"
                            disabled={true}
                          />
                        }
                      />
                      <AssessmentData
                        name="Writing"
                        price={priceMap.slw}
                        inputdata={
                          <Input
                            min={0}
                            type="number"
                            name="slw"
                            price={priceMap.slw}
                            value={state.slw}
                            onChange={onChangeHandler}
                            step="1"
                            disabled={true}
                          />
                        }
                      />
                      <AssessmentData
                        name="Speaking"
                        price={priceMap.sls}
                        inputdata={
                          <Input
                            min={0}
                            type="number"
                            name="sls"
                            price={priceMap.sls}
                            value={state.sls}
                            onChange={onChangeHandler}
                            step="1"
                            disabled={true}
                          />
                        }
                      />
                      {/* record and review */}
                      <tr>
                        <td className="flex justify-center bg-[#F2A900] rounded-xl p-1">
                          <h2 className="text-white">Record and Review</h2>
                        </td>
                      </tr>

                      <AssessmentData
                        name="Listening and Reading"
                        price={priceMap.srl}
                        inputdata={

                          <Input
                            data-testid="srl"
                            min={0}
                            type="number"
                            name="srl"
                            price={priceMap.srl}
                            value={state.srl}
                            onChange={onChangeHandler}
                            step="1"
                          />
                        }
                      />
                      <AssessmentData
                        name="Writing"
                        price={priceMap.srw}
                        inputdata={
                          <Input
                            min={0}
                            type="number"
                            name="srw"
                            price={priceMap.srw}
                            value={state.srw}
                            onChange={onChangeHandler}
                            step="1"
                          />
                        }
                      />
                      <AssessmentData
                        name="Speaking"
                        price={priceMap.srs}
                        inputdata={
                          <Input
                            min={0}
                            type="number"
                            name="srs"
                            price={priceMap.srs}
                            value={state.srs}
                            onChange={onChangeHandler}
                            step="1"
                          />
                        }
                      />
                      {/*  */}
                    </tbody>
                  </table>
                </div>
                <div className=" my-10 rounded-xl">
                  <p className="p-2 flex justify-center text-xl bg-[#F2A900] rounded-2xl">Bundle</p>
                  <table className="min-w-full ">
                    <thead className=" border-b border-gray-600">
                      <tr className="text-gray-400">
                        <th
                          scope="col"
                          className="text-sm  font-medium px-6 py-4 text-left"
                        >
                          Assessment Name
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium px-6 py-4 text-left"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium px-6 py-4 text-left"
                        >
                          Quantity
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <AssessmentData
                        name="AI Proctoring"
                        price={priceMap.bundleAI}
                        inputdata={
                          <Input
                            data-testid="bundleAI"
                            min={100}
                            type="number"
                            name="bundleAI"
                            price={priceMap.bundleAI}
                            value={state.bundleAI}
                            onChange={onChangeHandler}
                            step="1"
                          />
                        }
                      />
                      <AssessmentData
                        name="Record and Review"
                        price={priceMap.bundlerr}
                        inputdata={
                          <Input
                            data-testid="bundlerr"
                            min={100}
                            type="number"
                            name="bundlerr"
                            price={priceMap.bundlerr}
                            value={state.bundlerr}
                            onChange={onChangeHandler}
                            step="1"
                          />
                        }
                      />
                      <AssessmentData
                        name="Live Proctor"
                        price={priceMap.bundleLsp}
                        inputdata={
                          <Input
                            data-testid="bundleLsp"
                            min={100}
                            type="number"
                            name="bundleLsp"
                            price={priceMap.bundleLsp}
                            value={state.bundleLsp}
                            onChange={onChangeHandler}
                            step="1"
                            disabled={true}
                          />
                        }
                      />
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="bg-white rounded-xl  h-5/6 flex flex-col justify-center my-40 overflow-scroll scrollbar-hide">
                <p className="flex justify-center text-2xl my-3 text-[#F2A900]">
                  Total
                </p>
                <div className="flex flex-row p-10">
                  <div className="flex flex-col m-5 ">
                    <p className="m-2">Total Single Credits</p>
                    <p className=" m-2">Total Bundle Credits</p>
                    <p className=" m-2">Total</p>
                  </div>
                  <div className="flex flex-col m-5 ">
                    <p className="m-2">$ {singleTotal}</p>
                    <p className=" m-2">$ {bundleTotal}</p>
                    <p className=" m-2">$ {total}</p>
                  </div>
                </div>
                <div className="flex justify-center items-center text-red-600">
                  <p>{errorMsg}</p>
                </div>
                <div className="flex justify-center items-center">
                  <Button
                    data-testid="buttons"
                    type="submit"
                    className="bg-[#F2A900] rounded-xl p-2 m-5 disabled:bg-yellow-200"
                    buttonText="Pay NOW"
                    isLoading={isLoading}
                    disabled={bDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </BaseLayout>
    </>
  );
}

export default PurchaseCredits;
