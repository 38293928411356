import React, { useState } from "react";

const AuthContext = React.createContext({});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const initialExpires = localStorage.getItem("expire");
  const [token, setToken] = useState(initialToken);
  const [expire, setexpire] = useState(initialExpires);
  const userIsLoggedIn = !!token;

  const loginHadler = (token, exp) => {
    setexpire(exp);
    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("expire", exp);
  };
  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("expire");
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    expires: expire,
    login: loginHadler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
