import React, { useState } from "react";

function Input({
    max,
    min,
    htmlFor,
    labelInnerHtml,
    type,
    placeholder,
    name,
    disabled,
    onChange,
    required,
    value,
    price,
    styles,
    pattern,
    step,
    ...props
  }) {
  const handleChange = (e) => {
      onChange(e);
  }
  return (
    <>

      <label className="flex" htmlFor={htmlFor}>
        {labelInnerHtml}
      </label>
      <input 
        {...props}
        id={htmlFor}
        price={price}
        type={type}
        placeholder={placeholder}
        value={value||""}
        name={name}
        disabled={disabled}
        onChange={(e) => handleChange(e)}
        required={required}
        min={min}
        max={max}
        pattern={pattern}
        step={step}
        className={`w-full p-2 border-b rounded-3xl ring-blue-100 bg-[#75787B] text-white ${styles}`}
      />
    </>
  );
}

export default Input;
