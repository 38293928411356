import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import AuthContext from "../store/auth-context";
import { useNavigate } from "react-router-dom";

const host = "https://delivery-cambridge-api.talview.org/";
const instance = axios.create({
  baseURL: host,
});

const API = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [error401, setError401] = useState(null);

  useEffect(() => {
    const requestInterceptor = instance.interceptors.request.use(
      function (config) {
        let token = authContext.token;

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response && error.response.status === 401) {
          handleUnauthorized();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      instance.interceptors.request.eject(requestInterceptor);
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [authContext.token]);

  const handleUnauthorized = () => {
    setTimeout(() => {
      authContext.logout();
      navigate("/");
    }, 4000);
    throw new Error("Token has expired. Redirecting to the Login Page.");
  };

  const loginApi = async (username, password) => {
    try {
      const response = await instance.post("auth/token", {
        username: username,
        password: password,
      });

      return response;
    } catch (error) {
      console.log(error);
      throw new Error(error.response.data.message);
    }
  };

  const deleteCandidate = async (payload) => {
    try {
      const response = await instance.delete("invitations", { data: payload });
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const dashboardApi = async () => {
    try {
      const response = await instance.get("/credits");
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleUnauthorized();
      } else {
        throw new Error(error.message);
      }
    }
  };

  const paymentApi = async (payload) => {
    try {
      const response = await instance.post("payment/url", payload);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleUnauthorized();
      }
      throw new Error(error.message);
    }
  };

  const downloadTemplate = async () => {
    try {
      const response = await instance.get("bulk-upload-credit/template");
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleUnauthorized();
      }
      throw new Error(error.message);
    }
  };

  const fileUpload = async (payload) => {
    try {
      const response = await instance.post("bulk-upload-credit", payload);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleUnauthorized();
      }
      throw new Error(error.message);
    }
  };

  const candidateDetailsPagination = async (
    pageNumber = 1,
    status = "",
    assessmentId = ""
  ) => {
    try {
      const response = await instance.get(
        `invitations?page=${pageNumber}&pageSize=10&status=${status}&assessment_type=${assessmentId}`
      );
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return {
    loginApi,
    deleteCandidate,
    dashboardApi,
    paymentApi,
    downloadTemplate,
    fileUpload,
    candidateDetailsPagination,
  };
};

export default API;
