import { Link } from "react-router-dom";
import React from "react";

function PaymentFailed() {
  return (
    <div className=" flex flex-col items-center justify-center h-screen w-full">
      <div className=" border bg-[#060b26] bg-opacity-70  text-white p-4 m-2 rounded-2xl">
        Payment Failed click on Payment Page
      </div>
      <div className="m-2">
        <Link to="/PurchaseCredits">
          <span
            style={{ cursor: "pointer" }}
            rel="noopener noreferrer"
            className="px-8 py-3 font-semibold rounded bg-[#F2A900] text-gray-900"
          >
            Payment Page
          </span>
        </Link>
      </div>
    </div>
  );
}

export default PaymentFailed;
